<template>
  <v-container class="pt-7 pb-11">
    <v-progress-linear v-if="getLoading" height="2" indeterminate/>
    <template v-else>
      <v-row class="mt-0 mb-4">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="pa-0"/>
        </v-col>
      </v-row>
      <v-row class="mb-8" justify="space-between" align="center">
        <v-col cols="auto" class="text-left">
          <h1 class="text-h4">{{ pageHeader }}</h1>
        </v-col>
        <v-col cols="auto" class="py-0" v-if="company_id">
          <v-btn :to="{name: 'create-user', query: {company_id: company_id}}" color="primary" depressed rounded>
            <v-icon size="25" left class="mr-3">mdi-plus</v-icon>
            {{ 'createUser'|localize }}
          </v-btn>
        </v-col>
      </v-row>
      <div class="white elevation-2 rounded">
        <v-row class="my-0" v-if="company_id">
          <v-col class="py-0">
            <v-tabs
              v-model="tab"
              @change="$router.replace({query: $router.currentRoute.query, hash: tab})"
              background-color="transparent"
              class="grey--text text--lighten-1"
            >
              <v-tab :ripple="false" href="#general" class="text-none">
                {{ 'general'|localize }}
              </v-tab>
              <v-tab :ripple="false" href="#users" class="text-none">
                {{ 'users'|localize }}
              </v-tab>
              <v-tab :ripple="false" href="#relevancy" class="text-none">
                {{ 'relevancyTemplate'|localize }}
              </v-tab>
            </v-tabs>
            <v-divider/>
          </v-col>
        </v-row>
        <template v-if="tab === 'general'">
          <v-container class="px-4">
            <v-form ref="createCompany" lazy-validation>
              <v-row class="mb-2" align="center" justify="space-between">
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-2">{{ 'company'|localize }} *</h6>
                  <v-text-field
                    v-model.trim="name"
                    outlined
                    dense
                    :placeholder="'addYourCompanyName'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-2">{{ 'phoneNumber'|localize }} *</h6>
                  <v-text-field
                    v-model.trim="phone"
                    outlined
                    dense
                    :placeholder="'phoneNumber'|localize"
                    :rules="[validationRules.required_long]"
                  />
                </v-col>
              </v-row>
              <v-row class="mb-2" align="center" justify="space-between">
                <v-col cols="6">
                  <h6 class="text-subtitle-1 mb-2">{{ 'contactPersonEmails'|localize }}</h6>
                  <v-text-field
                    v-model="emails"
                    outlined
                    dense
                    :placeholder="'addEmails'|localize"
                    :hint="'addEmailsHint'|localize"
                    persistent-hint
                  />
                </v-col>
              </v-row>
              <v-row align="center" justify="space-between">
                <v-col cols="6">
                  <v-btn
                    v-if="company_id"
                    rounded
                    depressed
                    color="error"
                    :loading="deleteLoading"
                    :disabled="saveLoading || deleteLoading"
                    @click="deleteCompany"
                  >
                    {{ 'delete'|localize }}
                  </v-btn>
                </v-col>
                <v-col cols="6" class="d-flex justify-end">
                  <v-btn
                    rounded
                    depressed
                    color="primary"
                    :loading="saveLoading"
                    :disabled="saveLoading || deleteLoading"
                    @click="saveCompany"
                  >
                    {{ 'save'|localize }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </template>
        <template v-else-if="tab === 'users'">
          <CompanyUsers />
        </template>
        <template v-else>
          <v-container class="px-4">
            <CompanyRelevancy
              :company_id="company_id"
              :disabled="false"
            />
          </v-container>
        </template>
      </div>
    </template>
  </v-container>
</template>

<script>
  import localize from '@/filters/localize';
  import validationRules from '../helpers/validationRules';
  import api from '@/api/api';
  import methods from '@/helpers/methods';
  import CompanyUsers from '@/components/CompanyUsers';
  import CompanyRelevancy from '@/components/CompanyRelevancy.vue';

  export default {
    name: 'CreateCompany',
    components: {CompanyUsers, CompanyRelevancy},

    data() {
      return {
        validationRules: validationRules,
        company_id: this.$router.currentRoute.query.company_id,
        tab: this.initialHash(),
        name: '',
        phone: '',
        emails: '',
        getLoading: true,
        saveLoading: false,
        deleteLoading: false
      };
    },

    async mounted() {
      if(this.company_id) {
        try {
          let remoteData = await api.getCompanyProfile(this.company_id);
          this.setCompanyData(remoteData.data);
          this.getLoading = false;
        } catch (e) {
          this.getLoading = false;
        }
      } else {
        this.getLoading = false;
      }
    },

    computed: {
      breadcrumbsItems() {
        return [
          {
            text: localize('companies'),
            disabled: false,
            to: {name: 'admin-companies'}
          },
          {
            text: (this.company_id) ? localize('editCompany') : localize('createCompany'),
            disabled: true,
          },
        ]
      },
      queryData() {
        let queryData = {};
        if(this.company_id) queryData.id = this.company_id;
        queryData.name = this.name;
        queryData.phone = this.phone;
        queryData.email = this.emails.split(',').map(s => s.trim());
        return queryData;
      },
      pageHeader() {
        return (this.company_id) ? this.name : localize('createCompany');
      },
    },

    methods: {
      setCompanyData(request_data) {
        if(request_data.name) this.name = request_data.name;
        if(request_data.phone) this.phone = request_data.phone;
        if(request_data.emails) this.emails = request_data.emails;
      },
      async saveCompany() {
        if(this.$refs.createCompany.validate()) {
          try {
            this.saveLoading = true;
            if(this.company_id) {
              await api.updateCompany(this.queryData);
            } else {
              await api.createCompany(this.queryData);
            }
            this.saveLoading = false;
            methods.showGlobalSnackbar('success', localize('done'));
            this.$router.push({name: 'admin-companies'});
          } catch (e) {
            this.saveLoading = false;
            methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          }
        }
      },
      async deleteCompany() {
        try {
          this.deleteLoading = true;
          await api.deleteCompany(this.company_id);
          this.$router.push({name: 'admin-companies'});
          methods.showGlobalSnackbar('success', localize('done'));
          this.deleteLoading = false;
        } catch (e) {
          this.deleteLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
      initialHash() {
        const queryHash = this.$router.currentRoute.hash.slice(1);
        return queryHash ? queryHash : 'general';
      },
    }
  }
</script>
