<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="usersList"
      :loading="loading"
      loader-height="2"
      :sort-by="defaultSortBy"
      :sort-desc="defaultSortDesc"
      :items-per-page="-1"
      :no-data-text="'noDataAvailable'|localize"
      hide-default-footer
    >
      <template v-slot:item.name="{ item }">
        <router-link class="black--text" :to="{name: 'create-user', query: {company_id: company_id, user_id: item.id}}">
          {{ item.name }}
        </router-link>
      </template>
      <template v-slot:item.last_login="{ item }">
        <div v-if="item['last_login']" v-html="$options.filters.formatDate(item['last_login'])"/>
        <div v-else>--</div>
      </template>
      <template v-slot:item.password_last_changed="{ item }">
        <div v-if="item['password_last_changed']" v-html="$options.filters.formatDate(item['password_last_changed'])"/>
        <div v-else>--</div>
      </template>
      <template v-slot:item.settings="{ item }">
        <v-progress-circular
          v-if="deleteQueue.includes(item.id) || loginUserId === item.id"
          indeterminate
          :width="1"
          :size="20"
        />
        <v-menu v-else left transition="slide-y-transition" :disabled="!!loginUserId">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar v-bind="attrs" v-on="on" size="45" tile>
              <v-icon size="20">mdi-dots-vertical</v-icon>
            </v-avatar>
          </template>
          <v-list>
            <v-list-item link @click="loginAsUser(item)">
              <v-list-item-title>{{ 'loginAs'|localize }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="editUser(item)">
              <v-list-item-title>{{ 'edit'|localize }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="openDeleteUser(item)">
              <v-list-item-title>{{ 'delete'|localize }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <SimpleDialog
      ref="deleteUserDialog"
      :title="'deleteUserTitle'|localize"
      :text="'deleteUserText'|localize"
      :accept-text="'delete'|localize"
      :decline-text="'back'|localize"
      @decline="cancelDeleteUser"
      @accept="deleteUser"
    />
  </div>
</template>

<script>
  import api from '@/api/api';
  import localize from '@/filters/localize';
  import methods from '@/helpers/methods';
  import SimpleDialog from '@/components/common/SimpleDialog';
  import { ROLES } from '@/enums/roles';

  export default {
    name: 'CompanyUsers',
    components: {SimpleDialog},

    data() {
      return {
        company_id: this.$router.currentRoute.query.company_id,
        loading: true,
        defaultSortBy: 'title',
        defaultSortDesc: true,
        usersList: [],
        deleteQueue: [],
        deleteUserId: null,
        loginUserId: null
      };
    },

    mounted() {
      this.getUsers();
    },

    computed: {
      headers() {
        return [
          {text: localize('name'), value: 'name', sortable: true},
          {text: localize('email'), value: 'email', sortable: false},
          {text: localize('userCreated'), value: 'user_created', sortable: false},
          {text: localize('lastLogin'), value: 'last_login', sortable: false},
          {text: localize('passwordLastChanged'), value: 'password_last_changed', sortable: false},
          {text: '', value: 'settings', sortable: false, width: 100, align: 'center'},
        ];
      },
    },

    methods: {
      setUsersData(usersData) {
        this.usersList = usersData.data;
      },
      async getUsers() {
        try {
          this.loading = true;
          let usersData = await api.getCompanyUsers(this.company_id);
          this.setUsersData(usersData);
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      },
      editUser(user) {
        this.$router.push({name: 'create-user', query: {company_id: this.company_id, user_id: user.id}});
      },
      openDeleteUser(user) {
        this.$refs.deleteUserDialog.open();
        this.deleteUserId = user.id;
      },
      cancelDeleteUser() {
        this.deleteUserId = null;
      },
      async deleteUser() {
        let userId = this.deleteUserId;
        this.deleteUserId = null;
        this.deleteQueue.push(userId)
        try {
          await api.deleteSingleCompanyUser(userId, this.company_id);
          let usersData = await api.getCompanyUsers(this.company_id);
          this.setUsersData(usersData);
          this.deleteQueue = this.deleteQueue.filter(queueId => queueId !== userId)
        } catch (e) {
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
      async loginAsUser(user) {
        try {
          this.loginUserId = user['id'];
          let userData = await api.loginAsUser({email: user['email'], locale: 'se'});
          let localUserData = userData['data'];
          await api.logout();
          methods.clearUserData();
          if (localUserData.role === ROLES.COMPANY) {
            if (localUserData.companies.length === 1) {
              localUserData.company = localUserData.companies[0];
            } else {
              localUserData.company = localUserData.companies.filter(company => company['id'].toString() === this.company_id.toString())?.[0];
            }
          }
          methods.saveUserData(localUserData);
          methods.showGlobalSnackbar('success', `${localize('hello')} ${localUserData['name']}`);
          methods.afterLogin(localUserData['locale']);
        } catch (e) {
          this.loginUserId = null;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      }
    }
  }
</script>
