var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.usersList,"loading":_vm.loading,"loader-height":"2","sort-by":_vm.defaultSortBy,"sort-desc":_vm.defaultSortDesc,"items-per-page":-1,"no-data-text":_vm._f("localize")('noDataAvailable'),"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"black--text",attrs:{"to":{name: 'create-user', query: {company_id: _vm.company_id, user_id: item.id}}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.last_login",fn:function(ref){
var item = ref.item;
return [(item['last_login'])?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.formatDate(item['last_login']))}}):_c('div',[_vm._v("--")])]}},{key:"item.password_last_changed",fn:function(ref){
var item = ref.item;
return [(item['password_last_changed'])?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.formatDate(item['password_last_changed']))}}):_c('div',[_vm._v("--")])]}},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [(_vm.deleteQueue.includes(item.id) || _vm.loginUserId === item.id)?_c('v-progress-circular',{attrs:{"indeterminate":"","width":1,"size":20}}):_c('v-menu',{attrs:{"left":"","transition":"slide-y-transition","disabled":!!_vm.loginUserId},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"45","tile":""}},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.loginAsUser(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('loginAs')))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.editUser(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('edit')))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openDeleteUser(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('delete')))])],1)],1)],1)]}}])}),_c('SimpleDialog',{ref:"deleteUserDialog",attrs:{"title":_vm._f("localize")('deleteUserTitle'),"text":_vm._f("localize")('deleteUserText'),"accept-text":_vm._f("localize")('delete'),"decline-text":_vm._f("localize")('back')},on:{"decline":_vm.cancelDeleteUser,"accept":_vm.deleteUser}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }